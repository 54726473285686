<template>
  <div class="grey darken-4">
    <v-divider class="grey darken-2" />
    <v-container>
      <v-row class="py-12">
        <v-col
          v-for="(i, key, idx) in concreteServices"
          :key="key"
          cols="12"
          :id="key"
          class="py-0"
        >
            <!-- color="grey darken-1" -->
          <v-card
            elevation="12"
            :class="`${idx === 0 ? 'mb-16' : 'my-16'} grey darken-4 rounded-lg`"
          >
            <v-card-title class="text-h6 text-md-h4 py-2 primary white--text text-uppercase justify-space-between">
              <span
                v-if="i.serviceRoute"
                class="cursor"
                @click="$router.push(`/${i.serviceRoute}`)"
              >
                {{ i.title }}
              </span>
              <span v-else>
                {{ i.title }}
              </span>
              <v-btn
                v-if="i.serviceRoute"
                outlined
                small
                color="secondary"
                :to="`/${i.serviceRoute}`"
              >
                Learn More
              </v-btn>
            </v-card-title>
            <v-card-text class="pt-2">
              <div v-html="i.desc" class="grey--text text--lighten-2"/>
              <div class="my-4">
                <v-chip
                  v-for="b in i.bullets"
                  :key="b"
                  class="my-1 my-sm-2 mr-1 mr-sm-3 grey darken-2 white--text text-overline"
                  small
                >
                  {{ b }}
                </v-chip>
              </div>
              <v-row
                v-if="i.images && i.images.length > 0"
                justify="center"
              >
                <v-col
                  v-for="(image, idx) in i.images"
                  :key="image.uuid"
                  :class="$vuetify.breakpoint.mdAndUp ? 'cursor' : ''"
                  cols="6"
                  sm="4"
                  lg="3"
                  @click="$vuetify.breakpoint.mdAndUp ? openImageDialog(i, idx) : ''"
                >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                        flat
                        class="pa-0"
                        color="transparent"
                      >
                        <img
                          data-blink-ops="scale-crop: 600x400; scale-crop-position: smart_faces_points;"
                          :data-blink-uuid="image.uuid"
                          class="mx-auto rounded"
                          :alt="image.alt"
                          width="100%"
                          height="auto"
                        />
                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="secondary"
                            opacity="0.55"
                            class="mb-1"
                          >
                            <div class="text-center text-subtitle-1 pa-2 font-weight-bold">
                              {{ image.alt}}
                            </div>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <v-divider
            v-if="idx < Object.keys(concreteServices).length - 1"
            class="grey darken-3 my-12 pt-1"
          /> -->
        </v-col>
      </v-row>
    </v-container>
    <ImageRowDialog
      v-if="$vuetify.breakpoint.mdAndUp && activeImages.length > 0"
      :show="showImageDialog"
      :title="`${imageDialogTitle} - Concrete Services`"
      :initialImage="initialImage"
      :images="activeImages"
      @close="showImageDialog = false"
    />
  </div>
</template>

<script>
import { delay } from '@/core/utils'
import { concreteServices } from '../constants'
export default {
  components: {
    ImageRowDialog: () => import(/* webpackChunkName: "concrete-services-image-dialog" */ '@/core/components/ImageRowDialog')
  },
  data () {
    return {
      concreteServices,
      showImageDialog: false,
      imageDialogTitle: null,
      initialImage: 0,
      activeImages: []
    }
  },
  methods: {
    openImageDialog (i, idx) {
      this.initialImage = idx
      this.activeImages = i.images
      this.imageDialogTitle = i.title
      this.showImageDialog = true
    },
    closeImageDialog () {
      this.showImageDialog = false
      this.imageDialogTitle = null
      this.activeImages = []
    }
  },
  async mounted () {
    if (this.$route.hash) {
      await delay(100)
      this.$vuetify.goTo(this.$route.hash, { offset: 150, duration: 100 })
    }
  }
}
</script>
